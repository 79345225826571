import { Box, Theme } from '@mui/material';
import PlayerIcon from './PlayerIcon';

const styles = {
  circleWrapper: {
    position: 'relative',
    width: 30,
    height: 30,
    borderRadius: '50%',
  },
  circle: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderRadius: '50%',
    top: '50%',
    left: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transformStyle: 'preserve-3d',
  },
  cross: {
    position: 'relative',
    width: '0.5rem',
    height: '0.5rem',
    top: '0.9rem',
    left: '0.7rem',

    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      width: '1px',
      height: '100%',
      backgroundColor: (theme: Theme) => theme.palette.action.active,
    },

    '&::before': {
      transform: 'rotate(45deg)',
    },

    '&::after': {
      transform: 'rotate(-45deg)',
    },
  },
};

export default function CrossCapsIcon() {
  const players = [1, 2, 3, 4];

  return (
    <Box sx={styles.circleWrapper}>
      {players.map(player => {
        const angle = 90 + (180 * (2 * player + 1)) / players.length;
        const transform = `rotate(${angle}deg) translateX(min(10px)) rotate(${-angle}deg)`;

        return (
          <Box key={player} sx={[styles.circle, { transform }]}>
            <PlayerIcon size={0.5} />
          </Box>
        );
      })}

      <Box sx={styles.cross} />
    </Box>
  );
}
