import Drawer from '../../components/Drawer';
import ResetPasswordPage from '../ResetPasswordPage/ResetPasswordPage';

export interface ResetPasswordDrawerProps {
  open: boolean;
  onClose: () => void;
}

export default function ResetPasswordDrawer({
  open,
  onClose,
}: ResetPasswordDrawerProps) {
  return (
    <Drawer open={open} onClose={onClose}>
      <ResetPasswordPage />
    </Drawer>
  );
}
