import StarIcon from '@mui/icons-material/StarBorderOutlined';
import {
  Box,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading } from '../../components/Heading';
import { Spinner } from '../../components/Spinner';
import { useAuth } from '../../contexts/authContext';
import { LeaderboardUser, getLeaderboardUsers } from '../../firebase/api';
import MedalIcon from '../../icons/MedalIcon';
import { getRatingType } from '../ProfilePage/ProfilePage';

const styles = {
  tabs: {
    width: '100%',

    '& > div': {
      borderBottom: 1,
      borderColor: 'divider',
    },

    '& .MuiTab-root': {
      minWidth: 0,
      flexGrow: 1,
      flexBasis: 0,
      maxWidth: 'none',
    },
  },
  tableContainer: {
    maxHeight: 'calc(100vh - 300px)',
  },
  tableRow: {
    height: 58,

    '&:last-child td': {
      borderBottom: 0,
    },
  },
  selfTableRow: {
    position: 'sticky',
    bottom: 4,
    backgroundColor: 'background.paper',
    zIndex: 1,
    borderRadius: 1,
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',

    '& td': {
      borderBottom: 0,
    },
  },
  aboveSelfTableRow: {
    '& td': {
      borderBottom: 0,
    },
  },
  borderLeftRadius: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  borderRightRadius: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  top3: {
    borderRadius: 1,
    paddingLeft: 1.125,
    paddingRight: 0,
    paddingY: 1,
    marginLeft: -1.375,
    width: 39.5,
  },
  gold: {
    backgroundColor: '#F7C631',
    border: '2px solid #E3AA24',
    color: 'black',
  },
  silver: {
    backgroundColor: '#D1CCC5',
    border: '2px solid #B3ACA8',
    color: 'black',
  },
  bronze: {
    backgroundColor: '#CE834A',
    border: '2px solid #B37314',
    color: 'black',
  },
};

export const LeaderboardPage = () => {
  const { user: self } = useAuth();
  const navigate = useNavigate();
  const [users, setUsers] = useState<LeaderboardUser[] | undefined>(undefined);
  const [currentTab, setCurrentTab] = useState(0);

  const ratingType = getRatingType(currentTab);
  const ratingField = ('rating' + (ratingType ?? '')) as
    | 'rating'
    | 'rating7'
    | 'rating9'
    | 'rating11'
    | 'rating13';
  const hasMatchField = `hasMatch${ratingType ?? ''}` as
    | 'hasMatch'
    | 'hasMatch7'
    | 'hasMatch9'
    | 'hasMatch11'
    | 'hasMatch13';

  const leaderboardUsers = users
    ?.filter(user => user[hasMatchField])
    .toSorted((a, b) => b[ratingField] - a[ratingField]);

  useEffect(() => {
    const fetchUsersWithMatches = async () => {
      const fetchedUsers = await getLeaderboardUsers();
      setUsers(fetchedUsers);
    };

    fetchUsersWithMatches();
  }, [ratingType]);

  const loading = leaderboardUsers !== undefined;
  const userIndex = leaderboardUsers?.findIndex(user => user.id === self?.id);

  return (
    <Container component="main" maxWidth="sm">
      <Stack gap={4} py={2}>
        <Heading>Topplista</Heading>

        <Tabs
          value={currentTab}
          onChange={(_, newValue) => setCurrentTab(newValue)}
          aria-label="tabs"
          sx={styles.tabs}
        >
          <Tab value={0} icon={<StarIcon />} aria-label="main rating" />
          <Tab value={1} label="7" />
          <Tab value={2} label="9" />
          <Tab value={3} label="11" />
          <Tab value={4} label="13" />
        </Tabs>

        {loading ? (
          <>
            <TableContainer sx={styles.tableContainer}>
              <Table
                size="small"
                aria-label="leaderboard"
                stickyHeader
                sx={{ px: 1 }}
              >
                <TableHead>
                  <TableRow
                    sx={{ '& > th': { bgcolor: 'background.default' } }}
                  >
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">Capsare</TableCell>
                    <TableCell align="right">Rating</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {leaderboardUsers.map((user, index) => {
                    const isSelf = index === userIndex;
                    const isAboveSelf = index + 1 === userIndex;
                    const isTop3 = index < 3;

                    return (
                      <TableRow
                        key={user.nameLabel}
                        onClick={() =>
                          navigate(`/user/${user.id}`, {
                            state: { prevPathname: '/leaderboard' },
                          })
                        }
                        sx={[
                          styles.tableRow,
                          isSelf && styles.selfTableRow,
                          isSelf && { top: 37 + (userIndex !== 0 ? 4 : 0) },
                          isAboveSelf && styles.aboveSelfTableRow,
                        ]}
                      >
                        <TableCell sx={[isSelf && styles.borderLeftRadius]}>
                          <Box
                            sx={[
                              isTop3 && styles.top3,
                              index === 0 && styles.gold,
                              index === 1 && styles.silver,
                              index === 2 && styles.bronze,
                            ]}
                          >
                            #{index + 1}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center" gap={1}>
                            {user.numberOfGlassesMedal !== undefined && (
                              <MedalIcon>{user.numberOfGlassesMedal}</MedalIcon>
                            )}
                            <div>
                              <Typography>{user.nameLabel}</Typography>
                              <Typography
                                variant="caption"
                                color="text.secondary"
                              >
                                {user.associationLabel}
                              </Typography>
                            </div>
                          </Box>
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={[isSelf && styles.borderRightRadius]}
                        >
                          <Typography>
                            {Math.round(user[ratingField])}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Spinner />
        )}
      </Stack>
    </Container>
  );
};
