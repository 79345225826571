import CreateNewIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContinueIcon from '@mui/icons-material/Forward';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardActionArea,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CrossCapsIcon from '../../../icons/CrossCapsIcon';
import { JudgeCrossState } from '../../JudgeCrossPage/JudgeCrossPage';
import JudgeCrossCreateDrawer from './JudgeCrossCreateDrawer';
import JudgeCrossDrawer from './JudgeCrossDrawer';

export default function JudgeCrossTool() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [judgeCrossAccordionExpanded, setJudgeCrossAccordionExpanded] =
    useState(false);

  const storedState: JudgeCrossState | null = JSON.parse(
    localStorage.getItem('tools.judgeCross') || 'null',
  );

  const handleJudgeCrossAccordionChange = (
    event: SyntheticEvent,
    expanded: boolean,
  ) => {
    if (storedState === null) {
      event.stopPropagation();
      navigate('judge-cross/create');
    } else {
      setJudgeCrossAccordionExpanded(expanded);
    }
  };

  return (
    <>
      <Card>
        <Accordion
          expanded={judgeCrossAccordionExpanded}
          onChange={handleJudgeCrossAccordionChange}
          disableGutters
        >
          <CardActionArea>
            <AccordionSummary
              expandIcon={storedState !== null ? <ExpandMoreIcon /> : undefined}
              aria-controls="judge-cross-option-content"
            >
              <Box display="flex" alignItems="center" gap={3}>
                <CrossCapsIcon />
                <Typography>Döm korscaps</Typography>
              </Box>
            </AccordionSummary>
          </CardActionArea>

          <AccordionDetails sx={{ p: 0 }}>
            <MenuList>
              <MenuItem onClick={() => navigate('judge-cross')}>
                <ListItemIcon>
                  <ContinueIcon />
                </ListItemIcon>
                <ListItemText>Fortsätt</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => navigate('judge-cross/create')}>
                <ListItemIcon>
                  <CreateNewIcon />
                </ListItemIcon>
                <ListItemText>Skapa ny</ListItemText>
              </MenuItem>
            </MenuList>
          </AccordionDetails>
        </Accordion>
      </Card>

      <JudgeCrossCreateDrawer
        open={pathname === '/tools/judge-cross/create'}
        onClose={() => navigate('/tools')}
      />

      {storedState !== null && (
        <JudgeCrossDrawer
          open={pathname === '/tools/judge-cross'}
          onClose={() => navigate('/tools')}
          storedState={storedState}
        />
      )}
    </>
  );
}
