import { Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface HeadingProps {
  children?: ReactNode;
}

export function Heading({ children }: HeadingProps) {
  return (
    <Typography variant="h4" textAlign="center">
      {children}
    </Typography>
  );
}
