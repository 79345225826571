import {
  ThemeProvider as MuiThemeProvider,
  useMediaQuery,
} from '@mui/material';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { createTheme } from '../theme';

export type ColorMode = 'light' | 'dark' | 'system';

interface ThemeContextType {
  colorMode: ColorMode;
  setColorMode: (mode: ColorMode) => void;
  darkModeEnabled: boolean;
}

const ThemeContext = createContext<ThemeContextType>({
  colorMode: 'system',
  setColorMode: () => {},
  darkModeEnabled: false,
});

export function useTheme() {
  return useContext(ThemeContext);
}

export default function ThemeProvider({ children }: { children: ReactNode }) {
  const storedColorMode = localStorage.getItem('colorMode');
  const [colorMode, setColorMode] = useState<ColorMode>(
    storedColorMode !== null && storedColorMode !== 'undefined'
      ? JSON.parse(storedColorMode)
      : 'system',
  );
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const systemMode = prefersDarkMode ? 'dark' : 'light';
  const paletteMode = colorMode === 'system' ? systemMode : colorMode;

  const theme = useMemo(() => {
    return createTheme({ paletteMode });
  }, [paletteMode]);

  const setAndStoreColorMode = (mode: ColorMode) => {
    setColorMode(mode);
    localStorage.setItem('colorMode', JSON.stringify(mode));
  };

  return (
    <ThemeContext.Provider
      value={{
        colorMode,
        setColorMode: setAndStoreColorMode,
        darkModeEnabled: paletteMode === 'dark',
      }}
    >
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
}
