import Drawer from '../../../components/Drawer';
import JudgeCircleCreatePage from '../../JudgeCircleCreatePage/JudgeCircleCreatePage';

export interface JudgeCircleCreateDrawerProps {
  open: boolean;
  onClose: () => void;
}

export default function JudgeCircleCreateDrawer({
  open,
  onClose,
}: JudgeCircleCreateDrawerProps) {
  return (
    <Drawer open={open} onClose={onClose}>
      <JudgeCircleCreatePage />
    </Drawer>
  );
}
