import Drawer from '../../../components/Drawer';
import JudgeCrossCreatePage from '../../JudgeCrossCreatePage/JudgeCrossCreatePage';

export interface JudgeCrossCreateDrawerProps {
  open: boolean;
  onClose: () => void;
}

export default function JudgeCrossCreateDrawer({
  open,
  onClose,
}: JudgeCrossCreateDrawerProps) {
  return (
    <Drawer open={open} onClose={onClose}>
      <JudgeCrossCreatePage />
    </Drawer>
  );
}
