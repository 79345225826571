/**
 * Round a number to the nearest integer away from zero
 *
 * @param num The number to round
 */
export function roundAwayFromZero(num: number): number {
  return num >= 0 ? Math.floor(num + 0.5) : Math.ceil(num - 0.5);
}

/**
 * Calculate the expected score of a player against another player
 *
 * @param playerRating The rating of the player
 * @param opponentRating The rating of the opponent
 * @returns {number} The expected score of the player (a number between 0 and 1)
 */
function calculateExpectedScore(
  playerRating: number,
  opponentRating: number,
): number {
  const ratingDifference = Math.max(
    -400,
    Math.min(400, opponentRating - playerRating),
  );

  return 1 / (1 + 10 ** (ratingDifference / 400));
}

/**
 * Calculate the change in rating of a player after playing a match
 *
 * @param playerRating The rating of the player
 * @param opponentRating The rating of the opponent
 * @param score The score of the player (0 if the player lost, 1 if the player won)
 * @returns {number} The change in rating of the player
 */
export function calculateRatingDelta(
  playerRating: number,
  opponentRating: number,
  score: 0 | 1,
): number {
  const k = playerRating < 1500 ? 100 : playerRating < 2000 ? 50 : 25;
  const expectedScore = calculateExpectedScore(playerRating, opponentRating);
  const ratingDelta = k * (score - expectedScore);

  return roundAwayFromZero(Math.max(-playerRating, ratingDelta));
}
