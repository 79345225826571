import { Container, Stack } from '@mui/material';
import { useState } from 'react';
import PlayerCircle, {
  Player,
} from '../../components/PlayerCircle/PlayerCircle';
import { PointSystem } from '../../components/PointSystemToggle';
import CircleLeaderboard from './CircleLeaderboard';
import JudgeCircleInstructions from './JudgeCircleInstructions';

export interface JudgeCircleState {
  pointSystem: PointSystem;
  maxPoints: number;
  players: readonly Player[];
}

export interface JudgeCirclePageProps {
  storedState: JudgeCircleState;
}

export default function JudgeCirclePage({ storedState }: JudgeCirclePageProps) {
  const [currentState, setCurrentState] =
    useState<JudgeCircleState>(storedState);
  localStorage.setItem('tools.judgeCircle', JSON.stringify(currentState));

  const { pointSystem, maxPoints, players } = currentState;
  const isLivesPointSystem = pointSystem === 'lives';

  const setPlayers = (players: readonly Player[]) =>
    setCurrentState({ ...currentState, players });

  return (
    <Container component="main" maxWidth="sm">
      <Stack gap={6} mb={4}>
        <JudgeCircleInstructions isLivesPointSystem={isLivesPointSystem} />
        <PlayerCircle
          players={players}
          setPlayers={setPlayers}
          isLivesPointSystem={isLivesPointSystem}
          maxPoints={maxPoints}
        />
        <CircleLeaderboard
          players={players}
          isLivesPointSystem={isLivesPointSystem}
          maxPoints={maxPoints}
        />
      </Stack>
    </Container>
  );
}
