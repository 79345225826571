import { Button, Container, Stack, TextField } from '@mui/material';
import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading } from '../../components/Heading';
import PlayerNameAutocomplete from '../../components/PlayerNameAutocomplete';
import PointSystemToggle, {
  PointSystem,
} from '../../components/PointSystemToggle';
import { JudgeCrossState } from '../JudgeCrossPage/JudgeCrossPage';
import FieldConnectorLines from './FieldConnectorLines';

export default function JudgeCrossCreatePage() {
  const navigate = useNavigate();
  const [pointSystem, setPointSystem] = useState<PointSystem>('points');
  const [maxPoints, setMaxPoints] = useState(0);
  const [team1, setTeam1] = useState('');
  const [team1Player1, setTeam1Player1] = useState('');
  const [team1Player2, setTeam1Player2] = useState('');
  const [team2, setTeam2] = useState('');
  const [team2Player1, setTeam2Player1] = useState('');
  const [team2Player2, setTeam2Player2] = useState('');

  const isLivesPointSystem = pointSystem === 'lives';

  function resetForm() {
    setPointSystem('points');
    setMaxPoints(0);
    setTeam1('');
    setTeam1Player1('');
    setTeam1Player2('');
    setTeam2('');
    setTeam2Player1('');
    setTeam2Player2('');
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const playerProps = {
      points: 0,
      warnings: 0,
      ranking: 1,
    };

    const initialState: JudgeCrossState = {
      pointSystem,
      maxPoints,
      teams: [
        {
          id: crypto.randomUUID(),
          index: 0,
          name: team1,
          players: [
            {
              id: crypto.randomUUID(),
              index: 0,
              name: team1Player1,
              ...playerProps,
            },
            {
              id: crypto.randomUUID(),
              index: 1,
              name: team1Player2,
              ...playerProps,
            },
          ],
        },
        {
          id: crypto.randomUUID(),
          index: 1,
          name: team2,
          players: [
            {
              id: crypto.randomUUID(),
              index: 2,
              name: team2Player1,
              ...playerProps,
            },
            {
              id: crypto.randomUUID(),
              index: 3,
              name: team2Player2,
              ...playerProps,
            },
          ],
        },
      ],
    };

    localStorage.setItem('tools.judgeCross', JSON.stringify(initialState));
    navigate('judge-cross');
    resetForm();
  }

  return (
    <Container component="main" maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <Stack gap={4} p={2}>
          <Heading>Döm korscaps</Heading>

          <PointSystemToggle value={pointSystem} onChange={setPointSystem} />

          <TextField
            label={
              isLivesPointSystem ? 'Antal liv' : 'Antal poäng för att åka ut'
            }
            type="number"
            value={maxPoints || ''}
            onChange={event => setMaxPoints(Number(event.target.value))}
            required
            inputProps={{ min: 1 }}
          />

          <Stack gap={2} position="relative">
            <TextField
              label="Lag 1"
              value={team1}
              onChange={event => setTeam1(event.target.value)}
              required
            />
            <PlayerNameAutocomplete
              value={team1Player1}
              onChange={setTeam1Player1}
              sx={{ ml: 4 }}
              textFieldProps={{
                label: 'Capsare 1',
              }}
            />
            <PlayerNameAutocomplete
              value={team1Player2}
              onChange={setTeam1Player2}
              sx={{ ml: 4 }}
              textFieldProps={{
                label: 'Capsare 2',
              }}
            />
            <FieldConnectorLines />
          </Stack>

          <Stack gap={2} position="relative">
            <TextField
              label="Lag 2"
              value={team2}
              onChange={event => setTeam2(event.target.value)}
              required
            />
            <PlayerNameAutocomplete
              value={team2Player1}
              onChange={setTeam2Player1}
              sx={{ ml: 4 }}
              textFieldProps={{
                label: 'Capsare 1',
              }}
            />
            <PlayerNameAutocomplete
              value={team2Player2}
              onChange={setTeam2Player2}
              sx={{ ml: 4 }}
              textFieldProps={{
                label: 'Capsare 2',
              }}
            />
            <FieldConnectorLines />
          </Stack>

          <Button type="submit" variant="contained" sx={{ mb: 2 }}>
            Döm korscaps
          </Button>
        </Stack>
      </form>
    </Container>
  );
}
