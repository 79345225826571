import { Checkbox, FormControlLabel, FormGroup, Paper } from '@mui/material';
import { useTheme } from '../contexts/themeContext';

const styled = {
  checkboxField: {
    paddingX: 1.75,
    paddingY: 0.75,
    backgroundColor: 'transparent',
    border: 1,
    borderColor: '#c8c9cc',
  },
};

export interface CheckboxFieldProps {
  label: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function CheckboxField({ label, ...props }: CheckboxFieldProps) {
  const { checked } = props;
  const { darkModeEnabled } = useTheme();

  return (
    <Paper
      elevation={0}
      sx={[
        styled.checkboxField,
        !checked && { color: darkModeEnabled ? '#ffffff99' : '#00000099' },
      ]}
    >
      <FormGroup>
        <FormControlLabel control={<Checkbox {...props} />} label={label} />
      </FormGroup>
    </Paper>
  );
}
