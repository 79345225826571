import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import { useMemo, useState } from 'react';
import Drawer from '../../../components/Drawer';
import { ExtendedPlayer } from '../../../components/PlayerCircle/PlayerCircle';
import { TeamColor } from '../../../components/PlayerCircle/TeamCircle';
import JudgeCrossPage, {
  JudgeCrossState,
} from '../../JudgeCrossPage/JudgeCrossPage';
import { JudgeCrossMenu } from './JudgeCrossMenu';
import { SwapPlayersModal } from './SwapPlayersModal';

export interface JudgeCrossDrawerProps {
  open: boolean;
  onClose: () => void;
  storedState: JudgeCrossState;
}

export default function JudgeCrossDrawer({
  open,
  onClose,
  storedState,
}: JudgeCrossDrawerProps) {
  const [swapPlayersModalOpen, setSwapPlayersModalOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      actionButton={
        <>
          <IconButton
            onClick={event => setAnchorEl(event.currentTarget)}
            size="large"
            aria-haspopup="true"
            aria-controls={isMenuOpen ? 'judge-cross-menu' : undefined}
            aria-expanded={isMenuOpen ? 'true' : undefined}
          >
            <MoreVertIcon />
          </IconButton>

          <JudgeCrossMenu
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={() => setAnchorEl(null)}
            setSwapPlayersModalOpen={setSwapPlayersModalOpen}
          />
        </>
      }
    >
      <DrawerContent
        storedState={storedState}
        swapPlayersModalOpen={swapPlayersModalOpen}
        setSwapPlayersModalOpen={setSwapPlayersModalOpen}
      />
    </Drawer>
  );
}

export interface TestProps {
  storedState: JudgeCrossState;
  swapPlayersModalOpen: boolean;
  setSwapPlayersModalOpen: (open: boolean) => void;
}

function DrawerContent({
  storedState,
  swapPlayersModalOpen,
  setSwapPlayersModalOpen,
}: TestProps) {
  const [currentState, setCurrentState] =
    useState<JudgeCrossState>(storedState);
  localStorage.setItem('tools.judgeCross', JSON.stringify(currentState));

  const { pointSystem, maxPoints, teams } = currentState;
  const isLivesPointSystem = pointSystem === 'lives';

  const players: readonly ExtendedPlayer[] = useMemo(() => {
    return teams
      .flatMap(team =>
        team.players.map(player => ({
          ...player,
          team: {
            ...team,
            color: (team.index === 0
              ? 'blue'
              : team.index === 1
                ? 'orange'
                : undefined) as TeamColor,
            points: team.players.reduce(
              (acc, player) => acc + player.points,
              isLivesPointSystem ? maxPoints : 0,
            ),
            warnings: team.players.reduce(
              (acc, player) => acc + player.warnings,
              0,
            ),
          },
        })),
      )
      .toSorted((a, b) => a.index - b.index);
  }, [isLivesPointSystem, maxPoints, teams]);

  return (
    <>
      <JudgeCrossPage
        currentState={currentState}
        setCurrentState={setCurrentState}
      />
      <SwapPlayersModal
        open={swapPlayersModalOpen}
        onClose={() => setSwapPlayersModalOpen(false)}
        players={players}
        currentState={currentState}
        setCurrentState={setCurrentState}
      />
    </>
  );
}
