import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { FormEvent, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading } from '../../components/Heading';
import Judge from '../../components/PlayerCircle/JudgePiece';
import PlayerNameAutocomplete from '../../components/PlayerNameAutocomplete';
import PointSystemToggle, {
  PointSystem,
} from '../../components/PointSystemToggle';
import { JudgeCircleState } from '../JudgeCirclePage/JudgeCirclePage';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  circleWrapper: {
    position: 'relative',
    width: '100%',
    height: '40vh',
    borderRadius: '50%',
    marginX: 'auto',
  },
  circle: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderRadius: '50%',
    top: '50%',
    left: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transformStyle: 'preserve-3d',
  },
  playerName: {
    zIndex: 2,
    mt: '3.2rem',
    pointerEvents: 'none',
  },
  player: {
    alignItems: 'center',
    WebkitTapHighlightColor: 'transparent',
  },
  head: {
    height: '2rem',
    width: '2rem',
    border: (theme: Theme) => `1px solid ${theme.palette.text.primary}`,
    borderRadius: '2rem',
    backgroundColor: 'background.paper',
  },
  body: {
    height: '2.5rem',
    width: '4rem',
    border: (theme: Theme) => `1px solid ${theme.palette.text.primary}`,
    borderRadius: '2.5rem 2.5rem 0 0',
    backgroundColor: 'background.paper',
    justifyContent: 'center',
    alignItems: 'center',

    '& p': {
      marginTop: '0.2rem',
    },
  },
  isBlue: {
    '& div': {
      boxShadow:
        'inset 0 0 0.3rem rgba(0, 153, 255, 1), inset 0 0 0.2rem rgba(0, 153, 255, 0.8)',
    },
  },
};

export interface PlayerCirclePlayerProps {
  name: string;
  angle: number;
  selected?: boolean;
  onClick?: () => void;
}

function PlayerCirclePlayer({
  name,
  angle,
  selected = false,
  onClick,
}: PlayerCirclePlayerProps) {
  const transform = `rotate(${angle}deg) translateX(min(38vw, 220px)) rotate(${-angle}deg)`;

  return (
    <>
      <Box sx={[styles.circle, { transform }]} onClick={onClick}>
        <Stack alignItems="center">
          <Stack sx={[styles.player, selected && styles.isBlue]}>
            <Box sx={styles.head} />
            <Box sx={styles.body} />
          </Stack>
        </Stack>
      </Box>

      <Stack sx={[styles.circle, styles.playerName, { transform }]}>
        <Typography
          variant="caption"
          textAlign="center"
          whiteSpace="nowrap"
          fontWeight={500}
          lineHeight={1}
        >
          {name}
        </Typography>
      </Stack>
    </>
  );
}

export default function JudgeCircleCreatePage() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [pointSystem, setPointSystem] = useState<PointSystem>('lives');
  const [maxPoints, setMaxPoints] = useState(0);
  const [playerCount, setPlayerCount] = useState(0);
  const [names, setNames] = useState<readonly string[]>([]);
  const [selectedPlayerIndex, setSelectedPlayerIndex] = useState<
    number | undefined
  >(undefined);

  const isLivesPointSystem = pointSystem === 'lives';

  function resetForm() {
    setPage(0);
    setPointSystem('lives');
    setMaxPoints(0);
    setPlayerCount(0);
    setNames([]);
    setSelectedPlayerIndex(undefined);
  }

  function handleGoToSecondPage(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (names.length < playerCount) {
      setNames([...names, ...Array(playerCount - names.length).fill('')]);
    } else if (names.length > playerCount) {
      setNames(names.slice(0, playerCount));
    }

    setPage(1);
    setSelectedPlayerIndex(undefined);
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const initialState: JudgeCircleState = {
      pointSystem,
      maxPoints,
      players: names.map((name, index) => ({
        id: crypto.randomUUID(),
        index,
        name,
        points: isLivesPointSystem ? maxPoints : 0,
        warnings: 0,
        ranking: 1,
      })),
    };

    localStorage.setItem('tools.judgeCircle', JSON.stringify(initialState));
    navigate('judge-circle');
    resetForm();
  }

  useLayoutEffect(() => {
    if (selectedPlayerIndex !== undefined) {
      const input = document.querySelector(
        'input[name="player-name"]',
      ) as HTMLInputElement;

      if (input !== null) {
        input.focus();
      }
    }
  }, [selectedPlayerIndex]);

  return (
    <Container component="main" maxWidth="sm">
      <Stack gap={4} p={2}>
        <Heading>Döm cirkel</Heading>

        {page === 0 ? (
          <form onSubmit={handleGoToSecondPage}>
            <Stack gap={4}>
              <PointSystemToggle
                value={pointSystem}
                onChange={setPointSystem}
              />

              <TextField
                label={
                  isLivesPointSystem
                    ? 'Antal liv'
                    : 'Antal poäng för att åka ut'
                }
                type="number"
                value={maxPoints || ''}
                onChange={event => setMaxPoints(Number(event.target.value))}
                inputProps={{ min: 1 }}
                required
              />

              <TextField
                label="Antal capsare"
                type="number"
                value={playerCount || ''}
                onChange={event => {
                  const newPlayerCount = Number(event.target.value);
                  setPlayerCount(newPlayerCount);
                }}
                inputProps={{ min: 1 }}
                required
              />

              <Button type="submit" variant="contained">
                Nästa
              </Button>
            </Stack>
          </form>
        ) : page === 1 ? (
          <form
            onSubmit={handleSubmit}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
          >
            <Typography
              variant="body2"
              color="textSecondary"
              textAlign="center"
              mb={4}
            >
              Klicka på en capsare för att lägga till namn.
            </Typography>

            <Stack gap={4}>
              <Stack sx={styles.container}>
                <Box sx={styles.circleWrapper}>
                  {names.map((name, index) => {
                    const angle = 90 + (180 * (2 * index + 1)) / playerCount;

                    return (
                      <PlayerCirclePlayer
                        key={index}
                        name={name}
                        angle={angle}
                        selected={index === selectedPlayerIndex}
                        onClick={() => {
                          setSelectedPlayerIndex(
                            selectedPlayerIndex !== index ? index : undefined,
                          );
                        }}
                      />
                    );
                  })}
                </Box>

                <Judge />
              </Stack>

              {selectedPlayerIndex !== undefined && (
                <PlayerNameAutocomplete
                  value={names[selectedPlayerIndex]}
                  onChange={newValue => {
                    const newPlayerNames = [...names];
                    newPlayerNames[selectedPlayerIndex] = newValue;
                    setNames(newPlayerNames);
                  }}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      setSelectedPlayerIndex(
                        (selectedPlayerIndex + 1) % playerCount,
                      );
                    }
                  }}
                  textFieldProps={{
                    name: 'player-name',
                    label: `Capsare ${selectedPlayerIndex + 1}`,
                    required: true,
                  }}
                />
              )}

              <Stack>
                <Button type="submit" variant="contained" sx={{ mb: 2 }}>
                  Döm cirkel
                </Button>

                <Button
                  variant="text"
                  onClick={() => setPage(0)}
                  sx={{ mx: 'auto' }}
                >
                  Tillbaka
                </Button>
              </Stack>
            </Stack>
          </form>
        ) : null}
      </Stack>
    </Container>
  );
}
