import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Stack, Typography } from '@mui/material';
import PlayerIcon from '../../icons/PlayerIcon';

export interface JudgeCircleInstructionsProps {
  isLivesPointSystem: boolean;
}

export default function JudgeCircleInstructions({
  isLivesPointSystem,
}: JudgeCircleInstructionsProps) {
  return (
    <Box mx="auto">
      <Stack direction="row" gap={1} alignItems="center">
        <Typography component="span">Klicka</Typography>
        <PlayerIcon />
        <ArrowForwardIcon fontSize="small" />
        {isLivesPointSystem ? (
          <Typography component="span" sx={{ color: 'error.main' }}>
            ❤️–
          </Typography>
        ) : (
          <Typography component="span" sx={{ color: 'success.main' }}>
            💀+
          </Typography>
        )}
      </Stack>

      <Stack direction="row" gap={1} alignItems="center">
        <Typography component="span">Håll in</Typography>
        <PlayerIcon />
        <ArrowForwardIcon fontSize="small" />
        <div>
          {isLivesPointSystem ? (
            <Typography component="span" sx={{ color: 'success.main' }}>
              ❤️+
            </Typography>
          ) : (
            <Typography component="span" sx={{ color: 'error.main' }}>
              💀–
            </Typography>
          )}
          <Typography component="span"> / </Typography>
          <Typography component="span" sx={{ color: 'success.main' }}>
            ⚠️+
          </Typography>
          <Typography component="span"> / </Typography>
          <Typography component="span" sx={{ color: 'error.main' }}>
            ⚠️–
          </Typography>
        </div>
      </Stack>
    </Box>
  );
}
