import { Avatar, Badge, Box, Paper, Typography } from '@mui/material';
import { orange } from '@mui/material/colors';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext';
import { useTheme } from '../../contexts/themeContext';
import { User } from '../../firebase/api';
import capsatLogo from '../../icons/capsat.png';

export function getInitials(user: User) {
  const words = user.names?.[0]?.toUpperCase().split(' ').slice(0, 2) ?? [];
  return words.map(word => word[0]).join('');
}

export interface HeaderProps {
  badgeCount: number;
}

export function Header({ badgeCount }: HeaderProps) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { darkModeEnabled } = useTheme();

  const handleAvatarClick = () => {
    navigate('profile', {
      state: { prevPathname: location.pathname },
    });
  };

  return (
    <Box sx={{ position: 'sticky', top: 0, zIndex: 1000 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 2,
          bgcolor: 'background.default',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            flexGrow: 1,
          }}
          onClick={() => navigate('/')}
        >
          <img
            src={capsatLogo}
            alt="logo"
            height="36px"
            width="36px"
            style={{ filter: darkModeEnabled ? 'invert(1)' : undefined }}
          />
          <Box display="flex" alignItems="flex-start">
            <Typography variant="h5" component="span" fontWeight="bold">
              Capsat
            </Typography>
          </Box>
        </Box>

        {user !== null ? (
          <Badge
            badgeContent={badgeCount}
            color="primary"
            sx={{ '& .MuiBadge-badge': { right: 3, top: 3 } }}
          >
            {user.profilePicture !== undefined ? (
              <Avatar src={user.profilePicture} onClick={handleAvatarClick} />
            ) : (
              <Avatar sx={{ bgcolor: orange[700] }} onClick={handleAvatarClick}>
                {getInitials(user)}
              </Avatar>
            )}
          </Badge>
        ) : (
          <Avatar onClick={handleAvatarClick} />
        )}
      </Paper>
    </Box>
  );
}
