import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { useState } from 'react';
import { Match, rejectMatch } from '../../firebase/api';

interface InnerDialogProps {
  onClose: () => void;
  match: Match;
}

function InnerDialog({ onClose, match }: InnerDialogProps) {
  const [loading, setLoading] = useState(false);

  const handleDeleteResultClick = async () => {
    if (match.status !== 'pending') return;

    setLoading(true);
    await rejectMatch(match);
    setLoading(false);
  };

  return (
    <>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Är du säker på att du vill invalidera resultatet? Ratingar kommer att
          beräknas om. Detta går inte att ångra!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Avbryt</Button>
        <LoadingButton
          onClick={handleDeleteResultClick}
          color="error"
          loading={loading}
        >
          Radera resultat
        </LoadingButton>
      </DialogActions>
    </>
  );
}

export interface ConfirmRejectionDialogProps extends InnerDialogProps {
  open: boolean;
}

export default function ConfirmRejectionDialog({
  open,
  onClose,
  ...props
}: ConfirmRejectionDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <InnerDialog onClose={onClose} {...props} />
    </Dialog>
  );
}
