import SwapIcon from '@mui/icons-material/SwapHoriz';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';

export interface JudgeCrossMenuProps {
  open: boolean;
  onClose: () => void;
  anchorEl: null | HTMLElement;
  setSwapPlayersModalOpen: (open: boolean) => void;
}

export const JudgeCrossMenu = ({
  open,
  onClose,
  anchorEl,
  setSwapPlayersModalOpen,
}: JudgeCrossMenuProps) => {
  return (
    <Menu anchorEl={anchorEl} open={open} onClose={onClose} onClick={onClose}>
      <MenuItem onClick={() => setSwapPlayersModalOpen(true)}>
        <ListItemIcon>
          <SwapIcon fontSize="small" />
        </ListItemIcon>
        Byt plats på capsare
      </MenuItem>
    </Menu>
  );
};
