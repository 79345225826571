import { Typography } from '@mui/material';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  Player,
  isEliminated,
} from '../../components/PlayerCircle/PlayerCircle';

const styles = {
  tableHead: {
    '& > th': { backgroundColor: 'background.default' },
  },
  disabledRanking: {
    color: 'text.disabled',
  },
};

export interface CircleLeaderboardProps {
  players: readonly Player[];
  isLivesPointSystem: boolean;
  maxPoints: number;
}

export default function CircleLeaderboard({
  players,
  isLivesPointSystem,
  maxPoints,
}: CircleLeaderboardProps) {
  const sortedPlayers = players.toSorted((a, b) => a.ranking - b.ranking);

  const isPlayerInTheGame = (player: Player) =>
    !isEliminated(isLivesPointSystem, player.points, maxPoints);

  const gameOver = players.filter(isPlayerInTheGame).length <= 1;

  return (
    <TableContainer>
      <Table size="small" aria-label="leaderboard" stickyHeader>
        <TableHead>
          <TableRow sx={styles.tableHead}>
            <TableCell align="left"></TableCell>
            <TableCell align="left">Capsare</TableCell>
            <TableCell align="right">
              {isLivesPointSystem ? 'Liv' : 'Poäng'}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {sortedPlayers.map(player => (
            <TableRow key={player.id}>
              <TableCell
                sx={[
                  isPlayerInTheGame(player) &&
                    !gameOver &&
                    styles.disabledRanking,
                ]}
              >
                #{player.ranking}
              </TableCell>
              <TableCell>
                <Typography>{player.name}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>{Math.round(player.points)}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
