import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Container, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { sendPasswordResetEmail } from 'firebase/auth';
import { FormEvent, useState } from 'react';
import { auth } from '../../firebase/firebase';

export default function ResetPasswordPage() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (loading) return;

    setLoading(true);

    try {
      await sendPasswordResetEmail(auth, email, {
        url: 'https://www.capsat.se/',
      });

      setIsEmailSent(true);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Stack sx={{ mt: 15, textAlign: 'center' }}>
        <Typography component="h1" variant="h5">
          Återställ lösenord
        </Typography>

        <Typography color="text.secondary" mt={1}>
          Ange din e-postadress för att få en återställningslänk skickad till
          dig.
        </Typography>

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          {errorMessage !== undefined && (
            <Alert severity="error" onClose={() => setErrorMessage(undefined)}>
              {errorMessage}
            </Alert>
          )}

          <TextField
            id="email"
            name="email"
            label="Email"
            autoComplete="email"
            margin="normal"
            value={email}
            onChange={event => setEmail(event.target.value)}
            required
            fullWidth
          />

          <Box mt={3}>
            {isEmailSent ? (
              <Box display="flex" justifyContent="center" gap={1} p={1}>
                <Typography component="span">Kolla din e-post</Typography>
                <CheckIcon color="success" />
              </Box>
            ) : (
              <LoadingButton
                type="submit"
                variant="contained"
                loading={loading}
                fullWidth
              >
                Skicka återställningslänk
              </LoadingButton>
            )}
          </Box>
        </Box>
      </Stack>
    </Container>
  );
}
