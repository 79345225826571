import CreateNewIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContinueIcon from '@mui/icons-material/Forward';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardActionArea,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CircleCapsIcon from '../../../icons/CircleCapsIcon';
import { JudgeCircleState } from '../../JudgeCirclePage/JudgeCirclePage';
import JudgeCircleCreateDrawer from './JudgeCircleCreateDrawer';
import JudgeCircleDrawer from './JudgeCircleDrawer';

export default function JudgeCircleTool() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [judgeCircleAccordionExpanded, setJudgeCircleAccordionExpanded] =
    useState(false);

  const storedState: JudgeCircleState | null = JSON.parse(
    localStorage.getItem('tools.judgeCircle') || 'null',
  );

  const handleJudgeCircleAccordionChange = (
    event: SyntheticEvent,
    expanded: boolean,
  ) => {
    if (storedState === null) {
      event.stopPropagation();
      navigate('judge-circle/create');
    } else {
      setJudgeCircleAccordionExpanded(expanded);
    }
  };

  return (
    <>
      <Card>
        <Accordion
          expanded={judgeCircleAccordionExpanded}
          onChange={handleJudgeCircleAccordionChange}
          disableGutters
        >
          <CardActionArea>
            <AccordionSummary
              expandIcon={storedState !== null ? <ExpandMoreIcon /> : undefined}
              aria-controls="judge-circle-option-content"
            >
              <Box display="flex" alignItems="center" gap={3}>
                <CircleCapsIcon />
                <Typography>Döm cirkel</Typography>
              </Box>
            </AccordionSummary>
          </CardActionArea>

          <AccordionDetails sx={{ p: 0 }}>
            <MenuList>
              <MenuItem onClick={() => navigate('judge-circle')}>
                <ListItemIcon>
                  <ContinueIcon />
                </ListItemIcon>
                <ListItemText>Fortsätt</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => navigate('judge-circle/create')}>
                <ListItemIcon>
                  <CreateNewIcon />
                </ListItemIcon>
                <ListItemText>Skapa ny</ListItemText>
              </MenuItem>
            </MenuList>
          </AccordionDetails>
        </Accordion>
      </Card>

      <JudgeCircleCreateDrawer
        open={pathname === '/tools/judge-circle/create'}
        onClose={() => navigate('/tools')}
      />

      {storedState !== null && (
        <JudgeCircleDrawer
          open={pathname === '/tools/judge-circle'}
          onClose={() => navigate('/tools')}
          storedState={storedState}
        />
      )}
    </>
  );
}
