import { onAuthStateChanged } from 'firebase/auth';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { onUserStateChanged, User } from '../firebase/api';
import { auth } from '../firebase/firebase';

interface AuthContextType {
  user: User | null;
  isUserLoggedIn: boolean;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  isUserLoggedIn: false,
  loading: false,
});

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }: { children: ReactNode }) {
  const storedUser = localStorage.getItem('user');
  const [user, setUser] = useState<User | null>(
    storedUser !== null && storedUser !== 'undefined'
      ? JSON.parse(storedUser)
      : null,
  );
  const [loading, setLoading] = useState(true);

  const isUserLoggedIn = user !== null;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async currentUser => {
      if (currentUser !== null) {
        await onUserStateChanged(currentUser.uid, setUser);
      } else {
        setUser(null);
      }

      setLoading(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (user !== null) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  return (
    <AuthContext.Provider value={{ user, isUserLoggedIn, loading }}>
      {children}
    </AuthContext.Provider>
  );
}
