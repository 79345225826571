import * as firebaseAuth from 'firebase/auth';
import { createUser } from './api';
import { auth } from './firebase';

export const INITIAL_RATING = 1000;

export const addUser = async (
  names: string[],
  associations: string[],
  email: string,
  password: string,
) => {
  return firebaseAuth
    .createUserWithEmailAndPassword(auth, email, password)
    .then(({ user }) => {
      createUser({
        id: user.uid,
        names,
        associations,
        rating: INITIAL_RATING,
        rating7: INITIAL_RATING,
        rating9: INITIAL_RATING,
        rating11: INITIAL_RATING,
        rating13: INITIAL_RATING,
        unconfirmedRating: INITIAL_RATING,
        unconfirmedRating7: INITIAL_RATING,
        unconfirmedRating9: INITIAL_RATING,
        unconfirmedRating11: INITIAL_RATING,
        unconfirmedRating13: INITIAL_RATING,
        matchesToConfirm: [],
      });
    });
};

export const signInUser = async (email: string, password: string) => {
  return firebaseAuth.signInWithEmailAndPassword(auth, email, password);
};

export const signOut = async () => {
  return auth.signOut();
};
