import Drawer from '../../../components/Drawer';
import JudgeCirclePage, {
  JudgeCircleState,
} from '../../JudgeCirclePage/JudgeCirclePage';

export interface JudgeCircleDrawerProps {
  open: boolean;
  onClose: () => void;
  storedState: JudgeCircleState;
}

export default function JudgeCircleDrawer({
  open,
  onClose,
  storedState,
}: JudgeCircleDrawerProps) {
  return (
    <Drawer open={open} onClose={onClose}>
      <JudgeCirclePage storedState={storedState} />
    </Drawer>
  );
}
