import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import SystemModeIcon from '@mui/icons-material/SettingsBrightness';
import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import Container from '@mui/material/Container';
import { useTheme } from '../../contexts/themeContext';
import Drawer from '../Drawer';
import { Heading } from '../Heading';

const styles = {
  toggleButtonGroup: {
    width: '100%',

    '& > .MuiToggleButtonGroup-groupedHorizontal': {
      width: '100%',
      gap: 1,
    },
  },
};

export interface SettingsDrawerProps {
  open: boolean;
  onClose: () => void;
}

export default function SettingsDrawer({ open, onClose }: SettingsDrawerProps) {
  const { colorMode, setColorMode } = useTheme();

  return (
    <Drawer open={open} onClose={onClose}>
      <Container component="main" maxWidth="sm">
        <Stack gap={4} p={2}>
          <Heading>Inställningar</Heading>

          <div>
            <Typography variant="subtitle1" color="textSecondary">
              Läge
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={colorMode}
              onChange={(_, newMode) => setColorMode(newMode)}
              aria-label="Color mode"
              exclusive
              sx={styles.toggleButtonGroup}
            >
              <ToggleButton value="light">
                <LightModeIcon />
                Ljus
              </ToggleButton>
              <ToggleButton value="system">
                <SystemModeIcon />
                System
              </ToggleButton>
              <ToggleButton value="dark">
                <DarkModeIcon />
                Mörk
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Stack>
      </Container>
    </Drawer>
  );
}
