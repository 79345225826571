import { Box, Stack, Typography } from '@mui/material';

import { useMemo } from 'react';
import { Player } from '../../components/PlayerCircle/PlayerCircle';
import { Team } from '../../components/PlayerCircle/TeamCircle';

export interface TeamStandingRowProps {
  id: string;
  index: number;
  name: string;
  players: readonly Player[];
  isLivesPointSystem: boolean;
  maxPoints: number;
}

function TeamStandingRow({
  index,
  name,
  players,
  isLivesPointSystem,
  maxPoints,
}: TeamStandingRowProps) {
  const teamPoints = useMemo(() => {
    return players.reduce(
      (points, player) => points + player.points,
      isLivesPointSystem ? maxPoints : 0,
    );
  }, [players, isLivesPointSystem, maxPoints]);

  const teamWarnings = useMemo(() => {
    return players.reduce((warnings, player) => warnings + player.warnings, 0);
  }, [players]);

  const teamColor = useMemo(() => {
    if (index === 0) {
      return 'rgba(0, 153, 255, 0.8)';
    } else if (index === 1) {
      return 'rgba(255, 120, 0, 0.8)';
    } else {
      return 'gray';
    }
  }, [index]);

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <Box
        display="flex"
        justifyContent="center"
        borderRadius={1}
        bgcolor={teamColor}
        p={1}
        width="2rem"
        textAlign="center"
        color="white"
        alignItems="center"
      >
        {teamPoints}
      </Box>
      {teamWarnings > 0 && (
        <Box
          display="flex"
          alignItems="center"
          borderRadius={1}
          border="1px solid #aaa"
          height={35}
          pl={1}
          pr={1.5}
        >
          {teamWarnings}x
          <Box fontSize={20} mt={-0.5} mr={-0.75}>
            ⚠️
          </Box>
        </Box>
      )}
      <Typography>{name}</Typography>
    </Stack>
  );
}

export interface TeamStandingsProps {
  teams: readonly Team[];
  isLivesPointSystem: boolean;
  maxPoints: number;
}

export default function TeamStandings({
  teams,
  isLivesPointSystem,
  maxPoints,
}: TeamStandingsProps) {
  const sortedTeams = teams.toSorted((a, b) => a.index - b.index);

  return (
    <Stack gap={1}>
      {sortedTeams.map(({ id, index, name, players }) => (
        <TeamStandingRow
          key={id}
          id={id}
          index={index}
          name={name}
          players={players}
          isLivesPointSystem={isLivesPointSystem}
          maxPoints={maxPoints}
        />
      ))}
    </Stack>
  );
}
