import { Box, Stack, Theme } from '@mui/material';

const styles = {
  wrapper: {
    width: 'fit-content',
  },
  player: {
    alignItems: 'center',
    WebkitTapHighlightColor: 'transparent',
  },
  head: {
    border: (theme: Theme) => `1px solid ${theme.palette.text.primary}`,
    backgroundColor: 'background.paper',
  },
  body: {
    border: (theme: Theme) => `1px solid ${theme.palette.text.primary}`,
    backgroundColor: 'background.paper',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export interface PlayerIconProps {
  size?: number;
}

export default function PlayerIcon({ size = 1 }: PlayerIconProps) {
  return (
    <Box sx={styles.wrapper}>
      <Stack sx={styles.player}>
        <Box
          sx={[
            styles.head,
            {
              height: `${size / 2.5}rem`,
              width: `${size / 2.5}rem`,
              borderRadius: `${size / 2.5}rem`,
            },
          ]}
        />
        <Stack
          sx={[
            styles.body,
            {
              height: `${size / 2}rem`,
              width: `${size / 1.25}rem`,
              borderRadius: `${size / 2}rem ${size / 2}rem 0 0`,
            },
          ]}
        />
      </Stack>
    </Box>
  );
}
