export default function FieldConnectorLines() {
  return (
    <svg
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '200px',
        pointerEvents: 'none',
      }}
    >
      {/* Vertical line from team text field */}
      <line
        x1="16px"
        y1="56px"
        x2="16px"
        y2="173px"
        stroke="#ccc"
        strokeWidth="2"
      />

      {/* Horizontal lines to player text fields */}
      <line
        x1="16px"
        y1="100px"
        x2="32px"
        y2="100px"
        stroke="#ccc"
        strokeWidth="2"
      />
      <line
        x1="16px"
        y1="172px"
        x2="32px"
        y2="172px"
        stroke="#ccc"
        strokeWidth="2"
      />
    </svg>
  );
}
