import {
  Autocomplete,
  FilterOptionsState,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
} from '@mui/material';
import { matchSorter } from 'match-sorter';
import { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { getUsers } from '../firebase/api';

const filterOptions = (
  options: readonly string[],
  state: FilterOptionsState<string>,
) => {
  if (state.inputValue === '') {
    return [];
  }

  return matchSorter(options, state.inputValue, {
    baseSort: (a, b) => a.item.localeCompare(b.item),
  }).slice(0, 5);
};

export interface PlayerNameAutocompleteProps {
  value: string;
  onChange: (newValue: string) => void;
  excludeNames?: readonly string[];
  onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
  textFieldProps?: TextFieldProps;
  sx?: SxProps<Theme>;
}

export default function PlayerNameAutocomplete({
  value,
  onChange,
  excludeNames = [],
  textFieldProps,
  ...props
}: PlayerNameAutocompleteProps) {
  const [playerNames, setPlayerNames] = useState<readonly string[]>([]);

  const playerOptions = useMemo(
    () => playerNames.filter(name => !excludeNames.includes(name)),
    [playerNames, excludeNames],
  );

  useEffect(() => {
    const fetchUsers = async () => {
      const fetchedUsers = await getUsers();
      setPlayerNames(
        fetchedUsers
          .flatMap(user => user.names)
          .toSorted((a, b) => a.localeCompare(b)),
      );
    };

    fetchUsers();
  }, []);

  return (
    <Autocomplete
      {...props}
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      options={playerOptions}
      filterOptions={filterOptions}
      freeSolo
      disableClearable
      openOnFocus
      renderInput={params => (
        <TextField
          {...params}
          {...textFieldProps}
          onChange={event => onChange(event.target.value)}
          InputProps={{
            ...params.InputProps,
            ...textFieldProps?.InputProps,
          }}
        />
      )}
    />
  );
}
