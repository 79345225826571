import { PaletteMode } from '@mui/material';
import { createTheme as createMuiTheme } from '@mui/material/styles';

export interface ThemeOptions {
  paletteMode: PaletteMode;
}

const palette = {
  light: {
    mode: 'light',
    background: {
      default: '#fafafa',
      paper: '#ffffff',
    },
  },
  dark: {
    mode: 'dark',
    background: {
      default: '#0D1117',
      paper: '#151B23',
    },
    primary: {
      main: '#4493F8',
      contrastText: '#000000de',
    },
  },
} as const;

export function createTheme({ paletteMode }: ThemeOptions) {
  const paletteProps = paletteMode === 'dark' ? palette.dark : palette.light;

  return createMuiTheme({
    palette: {
      ...paletteProps,
      success: {
        main: '#3fb950',
      },
      error: {
        main: '#f85149',
      },
      action: {
        active: '#8d96a0',
      },
    },
    typography: {
      h4: {
        fontSize: '1.8rem',
      },
    },
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: paletteProps.background.default,
            backgroundImage: 'none',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: paletteProps.background.paper,
            backgroundImage: 'none',
          },
        },
      },
    },
  });
}
