import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/sv';
import { PwaPrompt } from 'react-ios-pwa-prompt-ts';
import AuthProvider from './contexts/authContext';
import ThemeProvider from './contexts/themeContext';
import { Router } from './pages/Router';

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sv">
          <Router />
          <PwaPrompt />
        </LocalizationProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
