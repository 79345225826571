import SwapIcon from '@mui/icons-material/SwapVert';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  FilterOptionsState,
  IconButton,
  Popper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Container from '@mui/material/Container';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { matchSorter } from 'match-sorter';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckboxField from '../../components/CheckboxField';
import { Heading } from '../../components/Heading';
import TextButton from '../../components/TextButton';
import { useAuth } from '../../contexts/authContext';
import {
  createMatch,
  getUsers,
  Match,
  onUserMatchesSnapshot,
  User,
} from '../../firebase/api';

const noJudgeUser: User = {
  id: 'NO_JUDGE',
  names: ['Ingen domare'],
  nameLabel: 'Ingen domare',
  associations: [],
  associationLabel: '',
  rating: 0,
  rating7: 0,
  rating9: 0,
  rating11: 0,
  rating13: 0,
  unconfirmedRating: 0,
  unconfirmedRating7: 0,
  unconfirmedRating9: 0,
  unconfirmedRating11: 0,
  unconfirmedRating13: 0,
  matchesToConfirm: [],
};

const getOptionKey = (option: User) => option.id;

const getOptionLabel = (option: User) => option.nameLabel ?? option.names[0];

export const HomePage = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [winnerInputValue, setWinnerInputValue] = useState('');
  const [loserInputValue, setLoserInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAfterSubmitPage, setShowAfterSubmitPage] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [swapRotation, setSwapRotation] = useState(0);
  const [userMatches, setUserMatches] = useState<Match[] | undefined>(
    undefined,
  );
  const { user } = useAuth();
  const navigate = useNavigate();

  const [winner, setWinner] = useState<User | undefined>(undefined);
  const [loser, setLoser] = useState<User | undefined>(undefined);
  const [judge, setJudge] = useState<User | undefined>(undefined);
  const [numberOfGlasses, setNumberOfGlasses] = useState<number | undefined>(
    undefined,
  );
  const [medalChallenge, setMedalChallenge] = useState(false);
  const [time, setTime] = useState<Dayjs | null>(null);
  const [location, setLocation] = useState<string | undefined>(undefined);
  const [event, setEvent] = useState<string | undefined>(undefined);
  const [curiosity, setCuriosity] = useState<string | undefined>(undefined);
  const [unranked, setUnranked] = useState(false);

  const missingValues =
    winner === undefined ||
    loser === undefined ||
    judge === undefined ||
    numberOfGlasses === undefined;

  const winnerOptions = users.filter(
    user => user.id !== loser?.id && user.id !== judge?.id,
  );
  const loserOptions = users.filter(
    user => user.id !== winner?.id && user.id !== judge?.id,
  );
  const judgeOptions = [
    noJudgeUser,
    ...users.filter(user => user.id !== winner?.id && user.id !== loser?.id),
  ];

  const showMedalChallenge = useMemo(() => {
    if (numberOfGlasses === undefined) return false;

    return (
      winner?.unconfirmedNumberOfGlassesMedal === numberOfGlasses ||
      loser?.unconfirmedNumberOfGlassesMedal === numberOfGlasses
    );
  }, [
    numberOfGlasses,
    winner?.unconfirmedNumberOfGlassesMedal,
    loser?.unconfirmedNumberOfGlassesMedal,
  ]);

  const last5Opponents = useMemo(() => {
    return (
      userMatches
        ?.filter(
          ({ winner, loser }) =>
            user?.id === winner.id || user?.id === loser.id,
        )
        .map(({ winner, loser }) => (winner.id === user?.id ? loser : winner))
        .filter(
          ({ id }, index, arr) =>
            arr.findIndex(user => user.id === id) === index &&
            [winner, loser, judge].every(user => user?.id !== id),
        )
        .slice(0, 5) ?? []
    );
  }, [userMatches, user?.id, winner, loser, judge]);

  const handleSwapClick = () => {
    setSwapRotation(swapRotation + 180);
    setWinner(loser);
    setLoser(winner);
  };

  const filterOptions = (
    options: User[],
    { inputValue }: FilterOptionsState<User>,
  ) => {
    const filteredOptions =
      inputValue === ''
        ? options.filter(
            option =>
              option.id === noJudgeUser.id ||
              option.id === user?.id ||
              last5Opponents.some(opponent => opponent.id === option.id),
          )
        : options;

    return matchSorter(filteredOptions, inputValue, {
      keys: ['names', 'associations'],
      baseSort: (a, b) => {
        if (a.item.id === noJudgeUser.id) return -1;
        if (b.item.id === noJudgeUser.id) return 1;

        if (a.item.id === user?.id) return -1;
        if (b.item.id === user?.id) return 1;

        for (const opponent of last5Opponents) {
          if (a.item.id === opponent.id) return -1;
          if (b.item.id === opponent.id) return 1;
        }

        return a.item.names[0].localeCompare(b.item.names[0]);
      },
    }).slice(0, 5);
  };

  const handleSubmit = async (formEvent: FormEvent<HTMLFormElement>) => {
    formEvent.preventDefault();

    if (missingValues) return;

    setLoading(true);
    await createMatch({
      winner,
      loser,
      judge: judge.id !== noJudgeUser.id ? judge : null,
      submitter: user,
      numberOfGlasses: numberOfGlasses,
      createdAt: time?.valueOf(),
      ...(medalChallenge && { medalChallenge }),
      ...(location && { location }),
      ...(event && { event }),
      ...(curiosity && { curiosity }),
      ...(unranked && { unranked }),
    });

    setShowAfterSubmitPage(true);
    setLoading(false);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const fetchedUsers = await getUsers();
      setUsers(
        fetchedUsers.toSorted((a, b) => {
          if (a.id === user?.id) return -1;
          if (b.id === user?.id) return 1;

          return a.names[0].localeCompare(b.names[0]);
        }),
      );
    };

    const fetchUserMatches = async () => {
      if (user?.id === undefined) return;

      const unsubscribe = onUserMatchesSnapshot(user.id, setUserMatches);

      return unsubscribe;
    };

    fetchUsers();
    fetchUserMatches();
  }, [user?.id]);

  const userAutocompleteProps = {
    filterOptions,
    getOptionKey,
    getOptionLabel,
    noOptionsText: 'Inga användare hittades',
    disableClearable: true,
    renderOption: ({ key, ...props }: any, option: User) => (
      <Box key={key} component="li" sx={{ height: 45 }} {...props}>
        <div>
          <Typography>{option.nameLabel}</Typography>
          <Typography variant="body2" fontSize={12} color="text.secondary">
            {option.associationLabel}
          </Typography>
        </div>
      </Box>
    ),
  } as const;

  if (showAfterSubmitPage)
    return (
      <Container component="main" maxWidth="sm">
        <Stack gap={4} p={2} mt={2} textAlign="center">
          <Typography variant="h6">Resultatet har skickats in!</Typography>
          <Typography>
            Vänligen invänta att en deltagare av matchen bekräftar resultatet
            under sin profil.
          </Typography>
          <Button variant="contained" onClick={() => navigate(0)}>
            Registrera nytt resultat
          </Button>
        </Stack>
      </Container>
    );

  return (
    <Container component="main" maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <Stack gap={4} p={2}>
          <Heading>Registrera resultat</Heading>

          <div>
            <Autocomplete
              value={winner ?? (null as any)}
              options={winnerOptions}
              onChange={(_, newValue) => setWinner(newValue)}
              renderInput={params => <TextField {...params} label="Vinnare" />}
              inputValue={winnerInputValue}
              onInputChange={(_, newInputValue) =>
                setWinnerInputValue(newInputValue)
              }
              PopperComponent={props => {
                if (
                  winnerInputValue.length === 0 &&
                  filterOptions(winnerOptions, {
                    inputValue: winnerInputValue,
                    getOptionLabel,
                  }).length === 0
                )
                  return null;

                return <Popper {...props} placement="bottom-start" />;
              }}
              {...userAutocompleteProps}
            />

            <Box display="flex" justifyContent="center" my={0}>
              <IconButton
                aria-label="delete"
                size="small"
                onClick={handleSwapClick}
                sx={{
                  transform: `rotate(${swapRotation}deg)`,
                  transition: 'all 0.2s linear',
                }}
              >
                <SwapIcon />
              </IconButton>
            </Box>

            <Autocomplete
              value={loser ?? (null as any)}
              options={loserOptions}
              onChange={(_, newValue) => setLoser(newValue)}
              renderInput={params => (
                <TextField {...params} label="Förlorare" />
              )}
              inputValue={loserInputValue}
              onInputChange={(_, newInputValue) =>
                setLoserInputValue(newInputValue)
              }
              PopperComponent={props => {
                if (
                  loserInputValue.length === 0 &&
                  filterOptions(loserOptions, {
                    inputValue: loserInputValue,
                    getOptionLabel,
                  }).length === 0
                )
                  return null;

                return <Popper {...props} placement="bottom-start" />;
              }}
              {...userAutocompleteProps}
            />
          </div>

          <Autocomplete
            value={judge ?? (null as any)}
            options={judgeOptions}
            onChange={(_, newValue) => setJudge(newValue)}
            renderInput={params => <TextField {...params} label="Domare" />}
            {...userAutocompleteProps}
          />

          <div>
            <Autocomplete
              value={numberOfGlasses?.toString() ?? ''}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Antal glas"
                  type="number"
                  onChange={event =>
                    setNumberOfGlasses(Number(event.target.value))
                  }
                  inputProps={{
                    ...params.inputProps,
                    min: 1,
                    max: 101,
                  }}
                />
              )}
              options={['7', '9', '11', '13']}
              onChange={(_, newValue) => setNumberOfGlasses(Number(newValue))}
              freeSolo
              disableClearable
            />

            <Collapse in={showMedalChallenge} timeout={500}>
              <Box mt={4}>
                <CheckboxField
                  label="PC-Mästarmedaljutmaning"
                  checked={medalChallenge}
                  onChange={event => setMedalChallenge(event.target.checked)}
                />
              </Box>
            </Collapse>

            <Box display="flex" justifyContent="flex-end" mt={1}>
              <TextButton onClick={() => setShowDetails(!showDetails)}>
                {showDetails ? 'Göm detaljer' : 'Lägg till detaljer +'}
              </TextButton>
            </Box>
          </div>

          <Box component={Collapse} in={showDetails} timeout={500} mt={-4}>
            <Stack gap={4} mt={1}>
              <DateTimePicker
                label="Tid"
                value={time ?? dayjs()}
                onChange={setTime}
                disableFuture
                disabled={showMedalChallenge && medalChallenge}
                slots={{
                  textField: params => (
                    <TextField
                      {...params}
                      value={time === null ? 'Nu' : params.value}
                    />
                  ),
                }}
              />

              <TextField
                label="Plats"
                value={location ?? ''}
                onChange={event => setLocation(event.target.value)}
              />

              <TextField
                label="Event"
                value={event ?? ''}
                onChange={event => setEvent(event.target.value)}
              />

              <TextField
                label="Kuriosa"
                value={curiosity ?? ''}
                onChange={event => setCuriosity(event.target.value)}
              />

              <CheckboxField
                label="Orankat"
                checked={unranked}
                onChange={event => setUnranked(event.target.checked)}
              />

              <Collapse in={!showMedalChallenge} timeout={500}>
                <CheckboxField
                  label="PC-Mästarmedaljutmaning"
                  checked={medalChallenge}
                  onChange={event => setMedalChallenge(event.target.checked)}
                />
              </Collapse>
            </Stack>
          </Box>

          <LoadingButton
            type="submit"
            variant="contained"
            disabled={missingValues}
            loading={loading}
            sx={{ mb: 2 }}
          >
            Skicka in
          </LoadingButton>
        </Stack>
      </form>
    </Container>
  );
};
