import { Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import Judge from './JudgePiece';
import PlayerPiece from './PlayerPiece';
import { ExtendedTeam } from './TeamCircle';

export interface Animation {
  id: number;
}

export interface Player {
  id: string;
  index: number;
  name: string;
  points: number;
  warnings: number;
  ranking: number;
}

export interface ExtendedPlayer extends Player {
  team: ExtendedTeam;
}

export function isEliminated(
  isLivesPointSystem: boolean,
  points: number,
  maxPoints: number,
) {
  return isLivesPointSystem ? points <= 0 : points >= maxPoints;
}

const calculateRankings = (
  players: readonly Player[],
  isLivesPointSystem: boolean,
  maxPoints: number,
) => {
  const sortedPlayers = players.toSorted((a, b) =>
    isLivesPointSystem ? b.points - a.points : a.points - b.points,
  );

  const isPlayerEliminated = (player: Player) =>
    isEliminated(isLivesPointSystem, player.points, maxPoints);

  const eliminatedPlayers = sortedPlayers.filter(isPlayerEliminated);

  return players.map(player => {
    const rankingIndex = sortedPlayers.findIndex(
      ({ points }) => points === player.points,
    );

    const eliminatedPlayersWithHigherRanking = eliminatedPlayers.filter(
      ({ ranking }) => ranking > player.ranking,
    );

    return {
      ...player,
      ranking: !isPlayerEliminated(player)
        ? rankingIndex + 1
        : players.length - eliminatedPlayersWithHigherRanking.length,
    };
  });
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  circleWrapper: {
    position: 'relative',
    width: '100%',
    height: 'min(90vw, 60vh, 800px)',
    borderRadius: '50%',
    marginX: 'auto',
  },
  circle: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderRadius: '50%',
    top: '50%',
    left: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transformStyle: 'preserve-3d',
  },
  playerName: {
    zIndex: 2,
    mt: '3.2rem',
    pointerEvents: 'none',
  },
  growingWarning: {
    position: 'absolute',
    right: 1.35,
    top: 0,
    fontSize: '16px',
    whiteSpace: 'nowrap',
    animation: 'grow 2s forwards',

    '@keyframes grow': {
      '100%': {
        transform: 'scale(4) translateY(-2px)',
        opacity: 0,
      },
    },
  },
};

export interface PlayerCirclePlayerProps {
  player: Player;
  angle: number;
  setPlayerPoints: (playerId: string, newPoints: number) => void;
  setPlayerWarnings: (playerId: string, newWarnings: number) => void;
  isLivesPointSystem: boolean;
  maxPoints: number;
}

function PlayerCirclePlayer({
  player,
  angle,
  setPlayerPoints,
  setPlayerWarnings,
  isLivesPointSystem,
  maxPoints,
}: PlayerCirclePlayerProps) {
  const [animatedWarnings, setAnimatedWarnings] = useState<Animation[]>([]);

  const { name, warnings } = player;
  const transform = `rotate(${angle}deg) translateX(min(40vw, 30vh, 400px)) rotate(${-angle}deg)`;

  return (
    <>
      <Box sx={[styles.circle, { transform }]}>
        <PlayerPiece
          player={player}
          setPoints={setPlayerPoints}
          setWarnings={setPlayerWarnings}
          setAnimatedWarnings={setAnimatedWarnings}
          isLivesPointSystem={isLivesPointSystem}
          eliminated={isEliminated(
            isLivesPointSystem,
            player.points,
            maxPoints,
          )}
        />
      </Box>

      <Stack sx={[styles.circle, styles.playerName, { transform }]}>
        <Typography
          variant="caption"
          textAlign="center"
          whiteSpace="nowrap"
          fontWeight={500}
          lineHeight={1}
        >
          {name}
        </Typography>

        <Box position="relative" component="span" height={0}>
          {warnings > 0 && (
            <>
              {'⚠️'.repeat(warnings)}
              {animatedWarnings.map(warning => (
                <Box key={warning.id} sx={styles.growingWarning}>
                  ⚠️
                </Box>
              ))}
            </>
          )}
        </Box>
      </Stack>
    </>
  );
}

export interface PlayerCircleProps {
  players: readonly Player[];
  setPlayers: (newPlayers: readonly Player[]) => void;
  isLivesPointSystem: boolean;
  maxPoints: number;
}

export default function PlayerCircle({
  players,
  setPlayers,
  isLivesPointSystem,
  maxPoints,
}: PlayerCircleProps) {
  const setPlayerPoints = (playerId: string, newPoints: number) => {
    const newPlayers = players.map(player =>
      player.id === playerId ? { ...player, points: newPoints } : player,
    );

    setPlayers(calculateRankings(newPlayers, isLivesPointSystem, maxPoints));
  };

  const setPlayerWarnings = (playerId: string, newWarnings: number) => {
    const newPlayers = players.map(player =>
      player.id === playerId ? { ...player, warnings: newWarnings } : player,
    );

    setPlayers(newPlayers);
  };

  return (
    <Stack sx={styles.container}>
      <Box sx={styles.circleWrapper}>
        {players.map((player, index) => {
          const angle = 90 + (180 * (2 * index + 1)) / players.length;

          return (
            <PlayerCirclePlayer
              key={player.id}
              player={player}
              angle={angle}
              setPlayerPoints={setPlayerPoints}
              setPlayerWarnings={setPlayerWarnings}
              isLivesPointSystem={isLivesPointSystem}
              maxPoints={maxPoints}
            />
          );
        })}
      </Box>

      <Judge />
    </Stack>
  );
}
