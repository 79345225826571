import { Link, Stack, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { Heading } from '../../components/Heading';

export const AboutPage = () => {
  return (
    <Container component="main" maxWidth="sm">
      <Stack gap={4} p={2}>
        <Heading>Om</Heading>

        <Typography variant="h5">Capsat</Typography>
        <Typography>
          På den här sidan kan du registrera dina matcher i{' '}
          <Link href="https://sv.wikipedia.org/wiki/Caps" underline="hover">
            caps
          </Link>{' '}
          och jämföra din rating med andra capsare. Vem som helst kan registrera
          ett resultat, men för att det ska bli godkänt så behöver det bekräftas
          av en deltagare i matchen på dennes profil. Den som skickar in ett
          resultat kan inte också bekräfta det. Vilka regler som gäller för
          matchen bestämmer ni själva.
        </Typography>

        <Typography>
          Vi capsar och tävlar mot varandra för att det är kul. Fusk och
          exploatering accepteras inte och leder därmed till att man blir
          bannad.
        </Typography>

        <Typography variant="h5">Rating</Typography>
        <Typography>
          Rating är ett mått på hur bra en capsare är relativt alla andra
          capsare. Alla börjar med en rating på 1000 som ökar om du vinner och
          minskar om du förlorar. Vinner du mot en capsare med högre rating så
          ökar den mer och förlorar du mot en capsare med lägre rating så
          minskar den mer. För matcher som har 7, 9, 11 eller 13 glas så finns
          även en egen rating för att avgöra hur bra en capsare är på just det
          antalet. Algoritmen är i hög grad baserad på{' '}
          <Link
            href="https://sv.wikipedia.org/wiki/Elo-rating"
            underline="hover"
          >
            Elo-systemet
          </Link>{' '}
          som används i t.ex. schack.
        </Typography>

        <Typography variant="h5">Kontakt</Typography>
        <Typography>
          Du kan nå mig,{' '}
          <Link
            href="https://teamsthlm.se/wiki/index.php/Halvan"
            underline="hover"
          >
            Halvan
          </Link>
          , för att efterfråga nya funktioner, rapportera buggar eller ändra
          felregistreringar här:{' '}
          <Link href="mailto:machalvan@hotmail.com" underline="hover">
            machalvan@hotmail.com
          </Link>
        </Typography>

        <Typography>Fler features kommer!</Typography>

        <div>
          <Typography
            component="p"
            variant="caption"
            color="text.secondary"
            align="center"
          >
            {'Copyright © '}
            <Link href="https://www.capsat.se/" color="inherit">
              Capsat
            </Link>{' '}
            {new Date().getFullYear()}
          </Typography>
          <Typography
            component="p"
            variant="caption"
            color="text.secondary"
            align="center"
          >
            Vectors and icons by{' '}
            <Link
              href="https://www.flaticon.com/authors/freepik"
              target="_blank"
              rel="noreferrer"
              color="inherit"
              underline="hover"
            >
              Freepik
            </Link>
            ,{' '}
            <Link
              href="https://iconscout.com/contributors/moudesain?ref=svgrepo.com"
              target="_blank"
              rel="noreferrer"
              color="inherit"
              underline="hover"
            >
              Moudesain
            </Link>{' '}
            in CC Attribution License
          </Typography>
        </div>
      </Stack>
    </Container>
  );
};
