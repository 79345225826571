import { useState } from 'react';
import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';

export const LoginPage = () => {
  const [showSignInForm, setShowSignInForm] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const toggleForm = () => setShowSignInForm(!showSignInForm);

  return (
    <div>
      {showSignInForm ? (
        <SignInForm
          email={email}
          password={password}
          toggleForm={toggleForm}
          setEmail={setEmail}
          setPassword={setPassword}
        />
      ) : (
        <SignUpForm
          email={email}
          password={password}
          toggleForm={toggleForm}
          setEmail={setEmail}
          setPassword={setPassword}
        />
      )}
    </div>
  );
};
