import MuiLink, { type LinkProps as MuiLinkProps } from '@mui/material/Link';

import {
  Link as RouterLink,
  type LinkProps as RouterLinkProps,
} from 'react-router-dom';

export default function Link(props: MuiLinkProps & RouterLinkProps) {
  return <MuiLink component={RouterLink} {...props} />;
}
