import { Typography } from '@mui/material';
import { type LinkProps } from '@mui/material/Link';

export default function TextButton(props: LinkProps) {
  return (
    <Typography
      variant="body2"
      color="primary"
      sx={{
        cursor: 'pointer',
        WebkitTapHighlightColor: 'transparent',
      }}
      {...props}
    />
  );
}
