import WinnerIcon from '@mui/icons-material/EmojiEvents';
import JudgeIcon from '@mui/icons-material/Gavel';
import LoserIcon from '@mui/icons-material/HorizontalRule';
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { Fragment, ReactNode, useState } from 'react';
import { Match, User } from '../../firebase/api';
import { MatchModal } from './MatchModal';

const NameTypography = ({ children }: { children: ReactNode }) => (
  <Typography
    variant="body2"
    whiteSpace="nowrap"
    textOverflow="ellipsis"
    overflow="hidden"
  >
    {children}
  </Typography>
);

export interface MatchListItemProps {
  match: Match;
  user: User;
  pendingHasOpacity: boolean;
}

export function MatchListItem({
  match,
  user,
  pendingHasOpacity,
}: MatchListItemProps) {
  const [openMatchModal, setOpenMatchModal] = useState(false);

  const handleOpenMatchModal = () => setOpenMatchModal(true);
  const handleCloseMatchModal = () => setOpenMatchModal(false);

  const isWinner = user.id === match.winner.id;
  const isLoser = user.id === match.loser.id;

  return (
    <Fragment key={match.id}>
      <ListItemButton disableGutters onClick={handleOpenMatchModal}>
        <ListItem
          secondaryAction={
            <Typography variant="body2">
              {match.numberOfGlasses} glas
            </Typography>
          }
          sx={[
            pendingHasOpacity && match.status === 'pending' && { opacity: 0.5 },
          ]}
        >
          <ListItemAvatar>
            {isWinner ? (
              <WinnerIcon color="success" />
            ) : isLoser ? (
              <LoserIcon color="error" />
            ) : (
              <JudgeIcon color="action" />
            )}
          </ListItemAvatar>
          <ListItemText
            primary={
              <Box mr={2}>
                {isWinner ? (
                  <NameTypography>{match.loser.nameLabel}</NameTypography>
                ) : isLoser ? (
                  <NameTypography>{match.winner.nameLabel}</NameTypography>
                ) : (
                  <Box display="flex">
                    <NameTypography>{match.winner.nameLabel}</NameTypography>
                    <Box mx={0.8}>vs</Box>
                    <NameTypography>{match.loser.nameLabel}</NameTypography>
                  </Box>
                )}
              </Box>
            }
          />
        </ListItem>
      </ListItemButton>

      <MatchModal
        open={openMatchModal}
        onClose={handleCloseMatchModal}
        match={match}
        user={user}
      />
    </Fragment>
  );
}

export interface MatchListProps {
  matches: Match[];
  user: User;
  pendingHasOpacity?: boolean;
}

export function MatchList({
  matches,
  user,
  pendingHasOpacity = true,
}: MatchListProps) {
  return (
    <List dense sx={{ width: '100%' }}>
      {matches?.map(match => (
        <MatchListItem
          key={match.id}
          match={match}
          user={user}
          pendingHasOpacity={pendingHasOpacity}
        />
      ))}
    </List>
  );
}
