import { createContext, ReactNode, useContext } from 'react';

const RatingTypeContext = createContext<number | undefined>(undefined);

export function useRatingType() {
  return useContext(RatingTypeContext);
}

export interface RatingTypeProviderProps {
  children: ReactNode;
  ratingType: number | undefined;
}

export default function RatingTypeProvider({
  children,
  ratingType,
}: RatingTypeProviderProps) {
  return (
    <RatingTypeContext.Provider value={ratingType}>
      {children}
    </RatingTypeContext.Provider>
  );
}
