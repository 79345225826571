import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Avatar,
  Box,
  Button,
  FormHelperText,
  InputAdornment,
  Stack,
} from '@mui/material';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useAuth } from '../../contexts/authContext';
import {
  NAME_SEPARATOR,
  updateUser,
  uploadProfilePicture,
} from '../../firebase/api';
import Drawer from '../Drawer';
import { Heading } from '../Heading';
import TextButton from '../TextButton';

const THIRTY_DAYS_IN_MS = 1000 * 60 * 60 * 24 * 30;

export interface EditProfileDrawerProps {
  open: boolean;
  onClose: () => void;
}

export default function EditProfileDrawer({
  open,
  onClose,
}: EditProfileDrawerProps) {
  const { user } = useAuth();
  const [profilePictureFile, setProfilePictureFile] = useState<
    File | undefined
  >(undefined);
  const [profilePicturePreview, setProfilePicturePreview] = useState<
    string | null
  >(user?.profilePicture ?? null);
  const [names, setNames] = useState(
    user?.names !== undefined && user?.names.length > 0 ? user.names : [''],
  );
  const [associations, setAssociations] = useState(
    user?.associations !== undefined && user?.associations.length > 0
      ? user.associations
      : [''],
  );
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const areNamesReadOnly =
    Date.now() - (user?.nameModifiedAt ?? 0) < THIRTY_DAYS_IN_MS;

  const handleFileInputChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files === null) return;

    const file = event.target.files[0];
    setProfilePictureFile(file);

    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = () => setProfilePicturePreview(reader.result as string);
      reader.readAsDataURL(file);
    } else {
      setProfilePicturePreview(null);
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (loading || user === null) return;

    setLoading(true);

    try {
      await updateUser({
        id: user.id,
        names: names.map(name => name.trim()),
        associations: associations
          .map(association => association.trim())
          .filter(association => association !== ''),
        ...(profilePictureFile !== undefined && {
          profilePicture: await uploadProfilePicture(user, profilePictureFile),
        }),
        ...(names.join(NAME_SEPARATOR) !== user?.nameLabel && {
          nameModifiedAt: Date.now(),
        }),
      });
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        throw error;
      }
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Drawer open={open} onClose={onClose}>
      <Container component="main" maxWidth="sm">
        <form onSubmit={handleSubmit}>
          <Stack gap={4} p={2}>
            <Heading>Redigera profil</Heading>

            {errorMessage !== undefined && (
              <Alert
                severity="error"
                onClose={() => setErrorMessage(undefined)}
              >
                {errorMessage}
              </Alert>
            )}

            <div>
              <Stack gap={4}>
                <Box display="flex" alignItems="center" gap={2}>
                  <Avatar
                    src={profilePicturePreview ?? undefined}
                    alt={user?.nameLabel}
                    sx={{ width: 64, height: 64 }}
                  />

                  <label htmlFor="profile-picker">
                    <TextField
                      id="profile-picker"
                      type="file"
                      onChange={handleFileInputChange}
                      inputProps={{ accept: 'image/*' }}
                      sx={{ display: 'none' }}
                    />
                    <Button variant="text" component="span">
                      Ladda upp profilbild
                    </Button>
                  </label>
                </Box>

                {names.map((name, index) => (
                  <TextField
                    key={index}
                    id="displayName"
                    name="displayName"
                    label="Visningsnamn"
                    value={name}
                    onChange={event => {
                      const newNames = [...names];
                      newNames[index] = event.target.value;
                      setNames(newNames);
                    }}
                    autoFocus={index > 0}
                    disabled={areNamesReadOnly}
                    required
                    fullWidth
                    InputProps={{
                      endAdornment:
                        !areNamesReadOnly && names.length > 1 ? (
                          <InputAdornment position="end">
                            <CloseIcon
                              fontSize="small"
                              aria-label="Ta bort namn"
                              onClick={() => {
                                const newNames = [...names];
                                newNames.splice(index, 1);
                                setNames(newNames);
                              }}
                            />
                          </InputAdornment>
                        ) : undefined,
                    }}
                  />
                ))}
              </Stack>

              <FormHelperText sx={{ mx: 1.75 }}>
                OBS: Namn kan endast ändras en gång på 30 dagar.
              </FormHelperText>

              {!areNamesReadOnly && (
                <Box display="flex" justifyContent="flex-end" mt={1}>
                  <TextButton onClick={() => setNames([...names, ''])}>
                    Lägg till +
                  </TextButton>
                </Box>
              )}
            </div>

            <div>
              <Stack gap={4}>
                {associations.map((association, index) => (
                  <TextField
                    key={index}
                    id="association"
                    name="association"
                    label="Förening"
                    value={association}
                    onChange={event => {
                      const newAssociations = [...associations];
                      newAssociations[index] = event.target.value;
                      setAssociations(newAssociations);
                    }}
                    autoFocus={index > 0}
                    fullWidth
                    InputProps={{
                      endAdornment:
                        associations.length > 1 ? (
                          <InputAdornment position="end">
                            <CloseIcon
                              fontSize="small"
                              aria-label="Ta bort förening"
                              onClick={() => {
                                const newAssociation = [...associations];
                                newAssociation.splice(index, 1);
                                setAssociations(newAssociation);
                              }}
                            />
                          </InputAdornment>
                        ) : undefined,
                    }}
                  />
                ))}
              </Stack>

              <Box display="flex" justifyContent="flex-end" mt={1}>
                <TextButton
                  onClick={() => setAssociations([...associations, ''])}
                >
                  Lägg till +
                </TextButton>
              </Box>
            </div>

            <LoadingButton type="submit" variant="contained" loading={loading}>
              Uppdatera
            </LoadingButton>
          </Stack>
        </form>
      </Container>
    </Drawer>
  );
}
