import { Box, CircularProgress, SxProps } from '@mui/material';

export interface SpinnerProps {
  sx?: SxProps;
}

export const Spinner = ({ sx }: SpinnerProps) => (
  <Box sx={{ textAlign: 'center', ...sx }}>
    <CircularProgress />
  </Box>
);
